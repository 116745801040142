/* width */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #bfbfbf8a;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #7B7FD6;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7B7FD6; 
}
